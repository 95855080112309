import Material from '@components/common/faq-material'
import { FaqContentType, FAQ_CONTENTS as defaultFAQs } from '@lib/data/faq'
import { useState } from 'react'

type Props = {
  faqContents?: FaqContentType[]
  title?: string
}
/** i18n support added */
const Faq = ({ faqContents, title }: Props) => {
  const [activeAccordion, setActiveAccordion] = useState('')

  const FAQs = faqContents || defaultFAQs

  return (
    <div className="container mx-auto flex flex-col lg:flex-row gap-x-20 max-w-4xl text-grey py-16">
      {!title && (
        <h2 className="font-600 text-3xl leading-tight lg:text-5xl mt-3 text-center lg:text-left">
          FAQ
        </h2>
      )}
      <div className="flex flex-col px-5 lg:px-0">
        {title && (
          <h2 className="font-600 text-2xl leading-tight lg:text-4xl my-6 text-center">
            {title}
          </h2>
        )}
        {FAQs?.map(({ questionName, acceptedAnswerText }, index) => (
          <Material
            key={index}
            title={questionName}
            activeAccordion={activeAccordion}
            setActiveAccordion={setActiveAccordion}
          >
            <p className="text-base">{acceptedAnswerText}</p>
          </Material>
        ))}
      </div>
    </div>
  )
}

export default Faq
