import MsgTrans from '@components/common/i18n/message-trans'
import { LinguiJSMessageDescriptor } from '@lib/types/common'
import classNames from 'classnames'
import { useRef } from 'react'
import { Minus, Plus } from 'react-feather'

type Props = {
  title: string | LinguiJSMessageDescriptor
  activeAccordion: string
  setActiveAccordion: React.Dispatch<React.SetStateAction<string>>
  children: JSX.Element
  isLightBorder?: boolean
}
const Material = ({
  title,
  activeAccordion,
  setActiveAccordion,
  children,
  isLightBorder = false,
}: Props) => {
  const getTitle = (title: string | LinguiJSMessageDescriptor) =>
    typeof title === 'string' ? title : title?.id
  const isOpen = activeAccordion === getTitle(title)
  const containerRef = useRef<HTMLDivElement>(null)
  return (
    <div>
      <button
        className="flex justify-between py-3 my-3 group w-full focus:outline-none"
        onClick={() => setActiveAccordion(isOpen ? '' : getTitle(title))}
      >
        <span className="text-lg text-grey font-600 group-hover:text-orange text-left w-4/5">
          <MsgTrans msg={title} />
        </span>
        {isOpen ? (
          <Minus className="text-grey-800 group-hover:text-orange w-4" />
        ) : (
          <Plus className="text-grey-800 group-hover:text-orange w-4" />
        )}
      </button>
      <div
        className={classNames(
          'text-sm text-grey overflow-hidden duration-200 border-b',
          {
            'border-grey-50': isLightBorder,
            'border-grey-300': !isLightBorder,
          }
        )}
        style={{
          maxHeight: isOpen ? `${containerRef.current.scrollHeight}px` : '0px',
        }}
      >
        <div ref={containerRef}>{children}</div>
      </div>
    </div>
  )
}

export default Material
